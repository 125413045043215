import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Stack,
  Grid,
  Card,
  CardContent,
} from '@mui/material'
import ExpandAccordionIcon from '@mui/icons-material/ExpandMore'
import { FunctionField, TextField } from 'react-admin'
import DoneIcon from '@mui/icons-material/CheckBoxOutlined'
import ToDoIcon from '@mui/icons-material/CheckBoxOutlineBlank'


export const AdditionalNotesAccordion: React.FC = () => {
  return (
    <Accordion defaultExpanded disableGutters>
      <AccordionSummary expandIcon={<ExpandAccordionIcon color="info" />}>
        <Typography variant="h6" alignItems="center" display="flex" gap={1}>
          <FunctionField
            render={(record: { state: string }) =>
              record.state !== 'pending-search' ? (
                <DoneIcon sx={{ mr: 1 }} />
              ) : (
                <ToDoIcon sx={{ mr: 1 }} />
              )
            }
          />
          Request: Additional notes
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Card variant="outlined">
                <CardContent>
                  <TextField source="additionalNotes" />
                </CardContent>
              </Card>
            </Stack>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
